import React, { PropsWithChildren } from 'react';
import './group.scss';
import classNames from 'classnames';

export type GroupProps = PropsWithChildren<{
  title: string | null;
  color: string | null | undefined;
}>;

export const Group: React.FC<GroupProps> = ({ title, color, children }) => (
  <div className={classNames('group', color ? `group--${color}` : undefined)}>
    <div className="group__title">{title}</div>
    <div className="group__content">{children}</div>
  </div>
);
