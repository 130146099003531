import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { LatestNewsQuery } from '../../../graphql-generated';
import { parseLink } from '../../../utils/parseLink';
import { LatestNewsItem } from '../../molecules/latestNewsItem';
import './latestNews.scss';

export type LatestNewsProps = {};

export const LatestNews: React.FC<LatestNewsProps> = ({}) => {
  const data = useStaticQuery<LatestNewsQuery>(query);

  const startPageLatestNews =
    data.wp?.options?.acfLatestNews?.startPageLatestNews;

  const latestNews = startPageLatestNews?.startPageLatestNews;

  if (!latestNews) return null;

  const latestNewsText = startPageLatestNews?.latestNewsText;

  return (
    <section className="latest-news">
      {latestNews.map((item) => {
        if (!item || item.__typename !== 'WpPost') return null;

        return (
          <LatestNewsItem
            key={item.id}
            link={parseLink(item.link)}
            text={latestNewsText}
          >
            {item.title}
          </LatestNewsItem>
        );
      })}
    </section>
  );
};

const query = graphql`
  query LatestNewsQuery {
    wp {
      options {
        acfLatestNews {
          __typename
          fieldGroupName
          startPageLatestNews {
            __typename
            latestNewsText
            startPageLatestNews {
              __typename
              ... on WpPost {
                id
                title
                link
              }
            }
          }
        }
      }
    }
  }
`;
