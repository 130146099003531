import React from 'react';
import { PageMainSectionContent } from '../../../graphql-generated';
import { parseLink } from '../../../utils/parseLink';
import { Button, ButtonType } from '../../atoms/button';
import { Separator, SeparatorColor } from '../../atoms/separator';
import { PostCard, PostCardSize, PostCardType } from '../postCard';
import { PostList } from '../postList';
import { Quote } from '../quote';

export type PostRendererProps = {
  content: PageMainSectionContent[];
  renderSmaller?: boolean;
  renderSeparators?: boolean;
};

const getPostRenderedName = (inputTypeName: string | undefined | null) => {
  if (!inputTypeName) return 'WpPage_Acfpagelayout_MainSection_Content_Unknown';

  const str = inputTypeName.split('_');
  const last = str[str.length - 1];

  return `WpPage_Acfpagelayout_MainSection_Content_${last}`;
};

//TODO somehow make SectionContent common and use here
export const PostRenderer: React.FC<PostRendererProps> = ({
  content,
  renderSmaller,
  renderSeparators,
}) => {
  if (!content) return null;

  const renderSeparator = (index: number) =>
    index > 0 ? <Separator color={SeparatorColor.Light} /> : <></>;
  return (
    <>
      {content.map((node, i) => {
        let rendered = null;

        if (!node) return null;

        // HACK to get cloned fields to work. See sectionContent.ts for more info
        node.__typename = getPostRenderedName(node?.__typename) as any;

        switch (node.__typename) {
          case 'WpPage_Acfpagelayout_MainSection_Content_PageTeaser':
            rendered = (
              <PostCard
                type={PostCardType.Vertical}
                size={renderSmaller ? PostCardSize.Medium : PostCardSize.Huge}
                fluidImage={node.teaserImage?.localFile?.childImageSharp?.fluid}
                title={node.teaserHeading}
                link={node.teaserExternalLink?.url ?? ''}
                target={node.teaserExternalLink?.target}
              >
                <Button type={ButtonType.Dark} as="button">
                  {node.teaserButtonText}
                </Button>
              </PostCard>
            );
            break;
          case 'WpPage_Acfpagelayout_MainSection_Content_PostList':
            rendered = (
              <PostList
                display={node.display}
                hideDates={node.hideDates}
                group={node.showAsGroup ? node.group : null}
                mode={node.mode}
                title={node.title}
                gridItems={node.gridItems}
                posts={node.posts ?? []}
                category={node.listCategory?.name}
                groupTeaserPost={node.group?.teaserPost ?? null}
                minimalListButtonLink={node.minimalListButtonLink?.uri}
                minimalListButtonText={node.minimalListButtonText}
                renderSmaller={renderSmaller}
                parentIndex={i}
              />
            );
            break;
          case 'WpPage_Acfpagelayout_MainSection_Content_Quote':
            rendered = (
              <Quote
                authorDisplayName={node.author?.name || ''}
                fluidImage={node.image?.localFile?.childImageSharp?.fluid}
                link={node.link?.url || ''}
                quoteHtml={node.quote || ''}
                category={node.category?.name}
              />
            );
            break;
          case 'WpPage_Acfpagelayout_MainSection_Content_SinglePost':
            const post = node.post;
            const hasVideo = post?.acfPostFields?.featuredVideo != null;
            const postImage = hasVideo
              ? post?.acfPostFields?.featuredVideoImg?.childImageSharp?.fluid
              : post?.featuredImage?.node?.localFile?.childImageSharp?.fluid;

            const title = post?.acfPostFields?.teaserTitle || post?.title;

            const excerpt = post?.acfPostFields?.teaserSummary || post?.excerpt;

            rendered = (
              <PostCard
                hideDates={node.hideDate}
                advertisement={post?.acfPostAdData?.advertisement}
                type={PostCardType.Vertical}
                size={renderSmaller ? PostCardSize.Medium : PostCardSize.Huge}
                description={excerpt}
                fluidImage={postImage}
                featuredVideo={post?.acfPostFields?.featuredVideo}
                title={title}
                link={parseLink(post?.link)}
                date={post?.date}
                category={post?.categories?.nodes?.[0]?.name}
              />
            );
            break;
          default:
            return <div>Not supported</div>;
        }
        return (
          <React.Fragment key={i}>
            {renderSeparators && renderSeparator(i)}
            <section>{rendered}</section>
          </React.Fragment>
        );
      })}
    </>
  );
};
