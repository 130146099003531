import React from 'react';
import { ListPost } from '../../../graphql-generated';
import { Dictionary } from '../../../types/Dictionary';
import { DetailedMinimalList } from '../detailedMinimalList';
import { ImageCardList } from '../imageCardList';
import { TeaserList } from '../teaserList';

export type PostListProps = {
  display: string | null;
  mode: string | null;
  title: string | null;
  group: {
    color: string | null;
  } | null;
  renderSmaller?: boolean;
  gridItems: string | null | undefined;
  posts: ListPost[];
  hideDates?: boolean | null;
  category?: string | null;
  groupTeaserPost: ListPost | null;
  minimalListButtonLink?: string | null;
  minimalListButtonText?: string | null;
  parentIndex?: number;
};

const postListTypes: Dictionary<React.FC<PostListProps>> = {
  detailed_grid: TeaserList,
  image_card_list: ImageCardList,
  detailed_list: DetailedMinimalList,
  minimal_list: DetailedMinimalList,
};

export const PostList: React.FC<PostListProps> = (props) => {
  const Component = postListTypes[props.display ?? ''] ?? null;
  return Component && <Component {...props} />;
};
