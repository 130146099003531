import classNames from 'classnames';
import React from 'react';
import { parseLink } from '../../../utils/parseLink';
import { Separator, SeparatorColor } from '../../atoms/separator';
import { PostCard, PostCardSize, PostCardType } from '../postCard';
import { PostListProps } from '../postList';
import './teaserList.scss';

export const TeaserList: React.FC<PostListProps> = ({
  title,
  posts,
  gridItems,
  hideDates,
}) => {
  return (
    <section
      className={classNames(
        'container teaser-list',
        'teaser-list--col',
        `teaser-list--${gridItems}-col`
      )}
    >
      {title && <div className="section-header">{title}</div>}
      <Separator color={SeparatorColor.Dark} />
      <div className="teaser-list__teasers">
        {posts.map((post) => {
          if (!post) return null;

          const hasVideo = post.acfPostFields?.featuredVideo != null;
          const image = hasVideo
            ? post.acfPostFields?.featuredVideoImg?.childImageSharp?.fluid
            : post.featuredImage?.node?.localFile?.childImageSharp?.fluid;

          const title = post.acfPostFields?.teaserTitle || post.title;
          const excerpt = post.acfPostFields?.teaserSummary || post.excerpt;

          return (
            <PostCard
              key={post.id}
              hideDates={hideDates}
              type={PostCardType.Vertical}
              size={PostCardSize.Medium}
              description={excerpt}
              fluidImage={image}
              featuredVideo={post.acfPostFields?.featuredVideo}
              title={title}
              link={parseLink(post.link)}
              date={post.date}
              category={post.categories?.nodes?.[0]?.name ?? ''}
              advertisement={post.acfPostAdData?.advertisement}
            />
          );
        })}
      </div>
    </section>
  );
};
