import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { parseLink } from '../../../utils/parseLink';
import { PlayButtonIcon } from '../../atoms/playButton';
import './imageCard.scss';

export type ImageCardProps = {
  title: string | null | undefined;
  description: string | null | undefined;
  link: string | null | undefined;
  fixedImage?: string | null | undefined;
  featuredVideo?: string | null | undefined;
};

// TODO: Change to gatsby-image with presentationWidth/height and use Fluid instead of fixed.
// Send in maxWidth/maxHeight to fluid instead.
export const ImageCard: React.FC<ImageCardProps> = ({
  title,
  description,
  link,
  fixedImage,
  featuredVideo,
}) => {
  const hasVideo = featuredVideo != null;
  return (
    <Link
      to={parseLink(link)}
      className={classNames('image-card', {
        'image-card--has-video': hasVideo,
      })}
    >
      {fixedImage ? (
        <div
          className="image-card__image"
          style={{ backgroundImage: `url(${fixedImage})` }}
        />
      ) : (
        <div className="image-card__image"></div>
      )}
      <div className="image-card__wrapper">
        {hasVideo && (
          <div className="image-card__video">
            <PlayButtonIcon />
          </div>
        )}
        <div className="image-card__content">
          <div className="image-card__title">{title}</div>
          <div className="image-card__description">{description}</div>
        </div>
      </div>
    </Link>
  );
};
