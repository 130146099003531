import React from 'react';
import { Separator, SeparatorColor } from '../components/atoms/separator';
import { PostRenderer } from '../components/molecules/postRenderer';
import { Seo } from '../components/molecules/seo';
import { LatestNews } from '../components/organisms/latestNews';
import { PageQuery } from '../graphql-generated';
import { isNotNull } from '../utils/isNotNull';
import { createForPage } from '../utils/seo/createForPage';
import { adjustPostLists } from '../utils/adjustPostList';

export const ListPage: React.FC<
  { data: PageQuery } & {
    showTitle?: boolean;
    fromCategoryOrPage?: boolean;
  }
> = ({ data, showTitle, fromCategoryOrPage }) => {
  const page = data.page;

  if (!page) return null;
  let main =
    page.acfPageLayout?.mainSection?.content?.filter(isNotNull) || [];
  let main2 =
    page.acfPageLayout?.bottomSection?.content?.filter(isNotNull) || [];
  
  let sidebar = page.acfPageLayout?.sidebar?.content?.filter(isNotNull) || [];
  let lowest =
    page.acfPageLayout?.lowestSection?.content?.filter(isNotNull) || [];
  const showLatestNews = page.acfPageLayout?.latestNews?.showLatestNewsAtTheTop;
  if(!!page.isFrontPage) {
    main2 = adjustPostLists([...main, ...sidebar,...main2,...lowest], main2);
  }

    main = adjustPostLists([], main);
    sidebar = adjustPostLists([], sidebar);
    lowest = adjustPostLists([], lowest);
    main2 = adjustPostLists([], main2);

  return (
    <>
      <Seo {...createForPage(data)} />
      <div className="container main-wrapper">
        <div className="main-wrapper__col-1">
          {showTitle && page.title && <h1>{page.title}</h1>}
          <Separator className="top-separator" color={SeparatorColor.Dark} />
          {showLatestNews && <LatestNews />}
          <PostRenderer content={main} />
        </div>
        <div className="main-wrapper__col-2">
          {sidebar && sidebar.length > 0 && (
            <>
              <Separator
                className="top-separator"
                color={SeparatorColor.Dark}
              />
              <PostRenderer
                content={sidebar as any}
                renderSmaller
                renderSeparators
              />
            </>
          )}
        </div>
      </div>
      <PostRenderer content={main2 as any} />
      <div className="container main-wrapper">
        <div className="main-wrapper__col-1">
          <PostRenderer content={lowest as any} />
        </div>
        <div className="main-wrapper__col-2" />
      </div>
    </>
  );
};
