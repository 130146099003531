import classNames from 'classnames';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import {
  PostQuery_post_acfPostAdData_advertisement,
  TechImageSharpFluid,
} from '../../../graphql-generated';
import { formatDate } from '../../../utils/formatDate';
import { isExternalLink } from '../../../utils/isExternalLink';
import { parseLink } from '../../../utils/parseLink';
import { toCamelCase } from '../../../utils/toCamelCase';
import { Category } from '../../atoms/category';
import { PlayButtonIcon } from '../../atoms/playButton';
import {
  Separator,
  SeparatorColor,
  SeparatorType,
} from '../../atoms/separator';
import './postCard.scss';

export enum PostCardSize {
  Huge = 'Huge',
  Medium = 'Medium',
  Small = 'Small',
}

export enum PostCardType {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
}

export type PostCardProps = {
  type: PostCardType;
  size: PostCardSize;
  title?: string | null;
  link: string;
  target?: string | null;
  description?: string | null;
  category?: string | null;
  date?: string;
  hideDates?: boolean | null;
  fluidImage: TechImageSharpFluid | null | undefined;
  featuredVideo?: string | null | undefined;
  children?: React.ReactNode;
  advertisement?: Omit<
    PostQuery_post_acfPostAdData_advertisement,
    '__typename'
  > | null;
};

export const PostCard: React.FC<PostCardProps> = ({
  type = PostCardType.Vertical,
  size = PostCardSize.Huge,
  title,
  link,
  target,
  description,
  category = '',
  date,
  hideDates = false,
  fluidImage,
  featuredVideo,
  children,
  advertisement,
}) => {
  const parsedDate = date ? new Date(date) : null;

  const image: any = { ...fluidImage, aspectRatio: 16 / 9 };

  const Component = isExternalLink(link) || target != null ? 'a' : Link;

  return (
    <article
      className={classNames(
        'post-card',
        `post-card--${toCamelCase(size)}`,
        `post-card--${toCamelCase(type)}`,
        {
          'post-card--advertisement': advertisement?.isadvertisement,
        }
      )}
    >
      <Component
        href={parseLink(link)}
        to={parseLink(link)}
        target={target ?? ''}
        rel={target === '_blank' ? 'noopener noreferrer' : ''}
        className="post-card__link"
      >
        <div className="post-card__image">
          {fluidImage && (
            <Img
              fluid={image}
              alt={String('Innehåll från ' + advertisement?.companyname ?? '')}
            />
          )}
          {featuredVideo && (
            <div className="post-card__video">
              <div className="post-card__video-play">
                <PlayButtonIcon
                  size={size === PostCardSize.Huge ? 'large' : 'default'}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="inner-wrapper post-card__inner-wrapper">
            {(!advertisement || !advertisement.isadvertisement) &&
              category &&
              !hideDates &&
              parsedDate && (
                <>
                  <Category className="post-card__category">
                    {category}
                  </Category>
                  <Separator
                    type={SeparatorType.Vertical}
                    color={SeparatorColor.DarkGray}
                    className="post-card__separator"
                  />
                  <span className="post-card__date">
                    {formatDate(parsedDate)}
                  </span>
                </>
              )}
            {advertisement?.isadvertisement && 'Annons'}
          </div>
          {<div className="post-card__title">{title}</div>}
          {description && (
            <div className="post-card__description">
              <Category className="post-card__title-inline">
                {category}
              </Category>
              <div
                className="post-card__text"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          )}
          {children && <div className="post-card__additional">{children}</div>}
        </div>
      </Component>
    </article>
  );
};
