export const adjustPostLists = (
  fourSectionsFlattened: any[],
  sectionToAdjust: any[]
) => {
  const usedLinks: string[] = [];
  fourSectionsFlattened.forEach((x: any) => {
    if (
      x.__typename.includes('Content_PostList') &&
      (x.display != 'detailed_grid' || x.mode != 'latest')
    ) {
      usedLinks.push(...(x.posts.map((p: any) => p?.link) || []));
    }
    if (x.__typename.includes('Content_SinglePost')) {
      usedLinks.push(x.post?.link);
    }
  });

  return sectionToAdjust.map((x: any) => {
    if (
      x.__typename.includes('Content_PostList') &&
      x.display == 'detailed_grid' &&
      x.mode == 'latest'
    ) {
      return {
        ...x,
        posts: [
          ...(x.posts || []).filter((p: any) => !usedLinks.includes(p.link)),
        ].slice(0, x.numberOfPosts ?? undefined),
      };
    }
    return x;
  });
};
