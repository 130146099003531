import React from 'react';
import { Category } from '../../atoms/category';
import { ImageCard } from '../imageCard';
import { PostListProps } from '../postList';
import './imageCardList.scss';

export const ImageCardList: React.FC<PostListProps> = ({
  title,
  posts,
  category,
}) => {
  return (
    <section className="large-teasers">
      <div className="container wrapper">
        <div className="large-teasers__inner">
          {category && <Category>{category}</Category>}
          <div className="large-teasers__title">{title}</div>
          <div className="large-teasers__teasers">
            {posts.map((post, i) => {
              const teaserHeading = post.acfPostFields?.teaserHeading || '';
              const teaserTitle = post.acfPostFields?.teaserTitle || post.title;
              const hasVideo = post.acfPostFields?.featuredVideo != null;
              const image = hasVideo
                ? post.acfPostFields?.featuredVideoImg?.childImageSharp?.fixed
                    ?.src
                : post.featuredImage?.node?.localFile?.childImageSharp?.fixed
                    ?.src;
              return (
                <ImageCard
                  key={post.id}
                  title={teaserHeading}
                  description={teaserTitle}
                  link={post.link}
                  fixedImage={image}
                  featuredVideo={post.acfPostFields?.featuredVideo}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
