import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { useContext } from 'react';
import { CategoriesContext } from '../../../contexts';
import { TechImageSharpFluid } from '../../../graphql-generated';
import { parseLink } from '../../../utils/parseLink';
import { Category } from '../../atoms/category';
import './quote.scss';

export type QuoteProps = {
  fluidImage: TechImageSharpFluid | null | undefined;
  category?: string | null;
  authorDisplayName: string;
  quoteHtml: string;
  link: string;
};

export const Quote: React.FC<QuoteProps> = ({
  fluidImage,
  category,
  authorDisplayName,
  quoteHtml,
  link,
}) => {
  const { getColorForCategory, activeCategory } = useContext(CategoriesContext);

  return (
    <section className="quote">
      <div className="inner-wrapper">
        {category && <Category>{category}</Category>}
        <div className="quote__author">{authorDisplayName}</div>
        <Link to={parseLink(link)}>
          <div
            className="quote__content"
            dangerouslySetInnerHTML={{ __html: quoteHtml }}
          />
        </Link>
      </div>
      <div className="quote__image">
        {fluidImage && <Img fluid={fluidImage as any} />}
      </div>
    </section>
  );
};
