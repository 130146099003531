import { Link } from 'gatsby';
import React from 'react';
import { ReactComponent as SimplePostCardIcon } from '../../../assets/icons/arrow-right-simple.svg';
import { parseLink } from '../../../utils/parseLink';
import './latestNewsItem.scss';

export type LatestNewsItemProps = React.PropsWithChildren<{
  link: string;
  text?: string | null;
}>;

export const LatestNewsItem: React.FC<LatestNewsItemProps> = ({
  link,
  text,
  children,
}) => (
  <Link to={parseLink(link)} className="latest-news-item">
    <div className="wrapper">
      <div className="latest-news-item__title">{text ?? 'Just nu'}</div>
      <div className="latest-news-item__text">{children}</div>
    </div>
    <SimplePostCardIcon className="latest-news-item__icon" />
  </Link>
);
