import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { parseLink } from '../../../utils/parseLink';
import { Button, ButtonType } from '../../atoms/button';
import { Group } from '../../atoms/group';
import { Separator, SeparatorColor } from '../../atoms/separator';
import { PostCard, PostCardSize, PostCardType } from '../postCard';
import { PostListProps } from '../postList';
import { Quote } from '../quote';
import { SimplePostCard } from '../simplePostCard';

//TODO split this component into few smaller ones
export const DetailedMinimalList: React.FC<PostListProps> = ({
  posts,
  display,
  title,
  group,
  hideDates,
  groupTeaserPost,
  minimalListButtonLink,
  minimalListButtonText,
  parentIndex,
  renderSmaller,
}) => {
  const renderedPosts = posts.map((post, index) => {
    if (display === 'minimal_list') {
      return (
        <React.Fragment key={post.id ?? index}>
          <Separator color={SeparatorColor.Light} />
          <SimplePostCard
            date={post.date}
            title={post.title}
            link={post.link}
            hideDate={hideDates}
            hasVideo={post.acfPostFields?.featuredVideo != null}
          />
        </React.Fragment>
      );
    }

    const hasVideo = post.acfPostFields?.featuredVideo != null;

    const category = post.categories?.nodes?.[0];
    const postImage = hasVideo
      ? post.acfPostFields?.featuredVideoImg?.childImageSharp?.fluid
      : post.featuredImage?.node?.localFile?.childImageSharp?.fluid;
    const categoryStyle = category?.acfCategory?.teaserStyle;
    const onlyPostStyle =
      post.acfPostFields?.teaserStyle !== 'from_category' &&
      post.acfPostFields?.teaserStyle;
    const postStyle = onlyPostStyle || categoryStyle || 'default';
    const title = post.acfPostFields?.teaserTitle || post.title;

    const excerpt = post.acfPostFields?.teaserSummary || post.excerpt;

    if (postStyle === 'quote') {
      const authorImage =
        post.acfPostFields?.teaserAuthorImage?.localFile?.childImageSharp
          ?.fluid;
      return (
        <React.Fragment key={post.id}>
          <Separator color={SeparatorColor.Light} />
          <Quote
            authorDisplayName={post.author?.node?.name ?? ''}
            fluidImage={authorImage || postImage}
            category={category?.name}
            quoteHtml={title ?? ''}
            link={parseLink(post.link)}
          />
        </React.Fragment>
      );
    }

    const removeSeparator =
      (group && index === 0) || (parentIndex === 0 && index === 0);

    return (
      <React.Fragment key={post.id}>
        {!removeSeparator && <Separator color={SeparatorColor.Light} />}
        <PostCard
          key={post.id}
          hideDates={hideDates}
          type={
            postStyle === 'large' || renderSmaller
              ? PostCardType.Vertical
              : PostCardType.Horizontal
          }
          size={
            display === 'detailed_list'
              ? postStyle === 'large'
                ? PostCardSize.Huge
                : renderSmaller
                ? PostCardSize.Medium
                : PostCardSize.Small
              : PostCardSize.Medium
          }
          description={excerpt}
          fluidImage={postImage}
          featuredVideo={post.acfPostFields?.featuredVideo}
          title={title}
          link={parseLink(post.link)}
          date={post.date}
          category={post.categories?.nodes?.[0]?.name ?? ''}
          advertisement={post.acfPostAdData?.advertisement}
        />
      </React.Fragment>
    );
  });

  return (
    <div
      className={classNames('post-list', {
        'post-list--detailed-list': display === 'detailed_list',
        'post-list--minimal-list': display === 'minimal_list',
      })}
    >
      {group ? (
        <Group title={title} color={group.color}>
          {groupTeaserPost && (
            <PostCard
              key={groupTeaserPost.id}
              type={PostCardType.Vertical}
              size={PostCardSize.Huge}
              description={groupTeaserPost.excerpt}
              fluidImage={
                groupTeaserPost.featuredImage?.node?.localFile?.childImageSharp
                  ?.fluid
              }
              title={groupTeaserPost.title}
              link={parseLink(groupTeaserPost.link)}
              date={groupTeaserPost.date}
              category={groupTeaserPost.categories?.nodes?.[0]?.name ?? ''}
            />
          )}
          {renderedPosts}
        </Group>
      ) : (
        <>
          {title && <div className="section-header">{title}</div>}
          {renderedPosts}
          {minimalListButtonLink && minimalListButtonText && (
            <Button
              type={ButtonType.Blue}
              href={parseLink(minimalListButtonLink)}
              as={Link as any}
            >
              {minimalListButtonText}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
