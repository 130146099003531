import classNames from 'classnames';
import React from 'react';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg';
import './play-button.scss';

export type PlayButtonIconProps = {
  size?: 'default' | 'large';
};

export const PlayButtonIcon = ({ size = 'default' }: PlayButtonIconProps) => {
  return (
    <div
      className={classNames('play-button', {
        [`play-button--${size}`]: true,
      })}
    >
      <PlayIcon />
    </div>
  );
};
