import { SeoProps } from '../../components/molecules/seo';
import { PageQuery } from '../../graphql-generated';
import { decodeHTML } from '../decodeHTML';
import { prependHost } from '../prependHost';

export const createForPage: (query: PageQuery) => SeoProps = (
  query: PageQuery
) => {
  const image =
    query?.page?.featuredImage?.node?.localFile?.childImageSharp?.fluid;

  return {
    title: decodeHTML(query.page?.title ?? ''),
    description: '', //decodeHTML(query.page? ?? ''),
    meta: [
      { property: 'og:image', content: prependHost(image?.src) },
      { property: 'og:image:width', content: image?.presentationWidth },
      {
        property: 'og:image:height',
        content: image?.presentationHeight,
      },
    ],
  };
};
