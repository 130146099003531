import { Link } from 'gatsby';
import React from 'react';
import { ReactComponent as SimplePostCardIcon } from '../../../assets/icons/arrow-right-simple.svg';
import { formatDate } from '../../../utils/formatDate';
import { parseLink } from '../../../utils/parseLink';
import { PlayButtonIcon } from '../../atoms/playButton';
import './simplePostCard.scss';

export type SimplePostCardProps = {
  title: string | null;
  link: string | null;
  date: string;
  hasVideo: boolean;
  hideDate?: boolean | null;
};

export const SimplePostCard: React.FC<SimplePostCardProps> = ({
  title,
  link,
  date,
  hasVideo,
  hideDate = false,
}) => {
  const parsedDate = new Date(date);
  return (
    <article className="simple-post-card">
      <Link to={parseLink(link)}>
        <div className="simple-post-card__outer">
          <div className="simple-post-card__inner">
            {hasVideo && (
              <div className="simple-post-card__play">
                <PlayButtonIcon />
              </div>
            )}
            <div>
              <div className="simple-post-card__title">{title}</div>

              {!hideDate && (
                <div className="simple-post-card__date">
                  {formatDate(parsedDate)}
                </div>
              )}
            </div>
          </div>
          <div className="simple-post-card__icon">
            <SimplePostCardIcon />
          </div>
        </div>
      </Link>
    </article>
  );
};
